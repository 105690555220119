import { navigate } from 'gatsby';
import React, { memo, useEffect } from 'react';
import Wrapper from '../components/shared/Wrapper';

const Home = () => {
  useEffect(() => {
    (async () => {
      navigate(`/app/dashboard`);
    })();
  }, []);

  return (
    <Wrapper>
      <div className="container xl:px-0 text-center md:text-left">
        ResumesMadeHere Resume Builder
      </div>
    </Wrapper>
  );
};

export default memo(Home);
